// directives/mask.js
export default {
    mounted(el) {
        function mask(event) {
            let keyCode;

            event.keyCode && (keyCode = event.keyCode);
            let pos = el.selectionStart;
            if (pos < 3) event.preventDefault();
            let matrix = "+7 (___) ___-__-__",
                i = 0,
                def = matrix.replace(/\D/g, ""),
                val = el.value.replace(/\D/g, ""),
                new_value = matrix.replace(/[_\d]/g, function (a) {
                    return i < val.length ? val.charAt(i++) || def.charAt(i) : a
                });
            i = new_value.indexOf("_");
            if (i != -1) {
                i < 5 && (i = 3);
                new_value = new_value.slice(0, i);
            }
            let reg = matrix.substr(0, el.value.length).replace(/_+/g, function (a) {
                return "\\d{1," + a.length + "}";
            }).replace(/[+()]/g, "\\$&");
            reg = new RegExp("^" + reg + "$");
            if (!reg.test(el.value) || el.value.length < 5 || (keyCode >= 48 && keyCode <= 57)) {
                el.value = new_value;
            }

            // Исправление для автозаполнения
            if (event.type === "blur" || event.type === "focusout") {
                if (el.value.replace(/\D/g, '').length < 11) {
                    el.value = '';
                }
            }
        }

        el.addEventListener("input", mask, false);
        el.addEventListener("focus", mask, false);
        el.addEventListener("blur", mask, false);
        el.addEventListener("keydown", mask, false);
    }
}
