<template>
  <div>
    <!-- Прелоадер -->
    <div v-if="isLoading" class="preloader">
      <div class="spinner"></div>
    </div>

    <section v-if="recipes.length" id="last-recipes" class="last-recipes-page">
      <div class="section-title">
        Высокая кухня с заботой о здоровье
      </div>
      <div class="favorites-recipes-list">
        <swiper
            :modules="modules"
            :slides-per-view="2"
            :space-between="20"
            :pagination="pagination2"
            :navigation="navigation2"
            :breakpoints="breakpoints2">
          <swiper-slide v-for="recipe in recipes" :key="recipe.id" class="favorites-recipes-list-item" @click="goToRecipe(recipe.id)">
            <img :src="recipe.image_url" :alt="recipe.title" class="favorites-recipes-list-item__img" loading="lazy" />
            <div class="favorites-recipes-list-item__info">
              <div class="favorites-recipes-list-item__info-title">
                {{ recipe.title }}
              </div>
              <div class="favorites-recipes-list-item__info-description">
                {{ recipe.preview }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>

    <section id="recipes">
      <div class="section-title">РЕЦЕПТЫ</div>
      <!-- Добавляем теги над списком рецептов -->
      <div class="tags-list">
        <button
            v-for="tag in tagsList"
            :key="tag.id"
            @click="filterByTag(tag.id)"
            :class="{ active: activeTag === tag.id }"
        >
          {{ tag.title }}
        </button>
      </div>

      <!-- Список рецептов -->
      <div class="recipes-list">
        <div
            class="recipes-list-item"
            v-for="(recipe, index) in filteredRecipes"
            :key="index"
            @click="goToRecipe(recipe.id)"
        >
          <div class="recipes-list-item__img">
            <img :src="recipe.image_url" :alt="recipe.title" loading="lazy" />
          </div>
          <div class="recipes-list-item__info">
            <div class="recipes-list-item__info-title">
              {{ recipe.title }}
            </div>
            <div class="recipes-list-item__info-description">
              {{ recipe.preview }}
            </div>
          </div>
        </div>
      </div>

      <!--      <div class="recipes-more">-->
<!--        <button class="button button-rose">Все рецепты</button>-->
<!--      </div>-->
    </section>

    <!-- Popup для входа -->
    <div v-if="showLoginPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeLoginPopup" class="popup__close"></button>
        <div class="popup__title">Авторизация</div>
        <div class="popup__content">
          <form @submit.prevent="login" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="login">Логин</label>
                <input type="text" maxlength="18" id="login" v-model="phone_number" name="phone_number" v-mask>
                <span v-if="loginFormErrors.phone_number" class="error-message">{{ loginFormErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="password">Пароль</label>
                <input
                    :type="passwordVisible ? 'text' : 'password'"
                    maxlength="255"
                    id="password"
                    v-model="password"
                    name="password"
                />
                <span class="password-toggle" @click="togglePasswordVisibility">
                  <i class="far" :class="passwordVisible ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
                <span v-if="loginFormErrors.password" class="error-message">{{ loginFormErrors.password }}<br></span>
                <span class="link" @click="openResetPasswordPopup">Забыли пароль?</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Войти</button>
              </div>
            </div>
            <div class="field-row field-row-account">
              <div class="field-item">
                Нет аккаунта?
                <button class="button button-white" @click="openRegisterPopup">Регистрация</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- Popup для регистрации -->
    <div v-if="showRegisterPopup" class="popup">
      <div class="popup__overlay"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopup" class="popup__close"></button>
        <div class="popup__title">Регистрация</div>
        <div class="popup__content">
          <form @submit.prevent="register" autocomplete="off">
            <!-- Поле Имя -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-name">Имя</label>
                <input type="text" maxlength="255" id="reg-name" v-model="registerForm.first_name" @input="removeSpacesFirstName" name="first_name"
                       autocomplete="off"
                       @blur="clearError('first_name')"
                       :class="{ 'is-error': errors.first_name }">
                <span v-if="errors.first_name" class="error-message">{{ errors.first_name }}</span>
              </div>
            </div>
            <!-- Поле Фамилия -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="last-name">Фамилия</label>
                <input type="text" maxlength="255" id="last-name" v-model="registerForm.last_name" @input="removeSpacesLastName" name="last_name"
                       autocomplete="off"
                       @blur="clearError('last_name')"
                       :class="{ 'is-error': errors.last_name }">
                <span v-if="errors.last_name" class="error-message">{{ errors.last_name }}</span>
              </div>
            </div>
            <!-- Поле Номер телефона -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="registerForm.phone_number" name="phone_number"
                       autocomplete="off"
                       @blur="clearError('phone_number')"
                       :class="{ 'is-error': errors.phone_number }" v-mask>
                <span v-if="errors.phone_number" class="error-message">{{ errors.phone_number }}</span>
              </div>
            </div>
            <!-- Поле Email -->
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-email">Email</label>
                <input type="text" maxlength="255" id="reg-email" v-model="registerForm.email" name="email"
                       autocomplete="off"
                       @blur="clearError('email')"
                       :class="{ 'is-error': errors.email }">
                <span v-if="errors.email" class="error-message">{{ errors.email }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с обработкой персональных данных -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-personality">
                  <input type="checkbox" id="reg-personality" v-model="registerForm.agree_personal_data" @blur="clearError('agree_personal_data')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank">с обработкой персональных данных</a></span>
                </label>
                <span v-if="errors.agree_personal_data" class="error-message">{{ errors.agree_personal_data }}</span>
              </div>
            </div>
            <!-- Чекбокс согласия с правилами акции -->
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="reg-rules">
                  <input type="checkbox" id="reg-rules" v-model="registerForm.agree_rules" @blur="clearError('agree_rules')">
                  <span>Согласен(на) <a class="link" v-if="mainSettings.rules" v-bind:href="mainSettings.rules" target="_blank" @click="handleRulesButtonClick()">с правилами акции</a></span>
                </label>
                <span v-if="errors.agree_rules" class="error-message">{{ errors.agree_rules }}</span>
              </div>
            </div>
            <!-- Кнопка для отправки формы регистрации -->
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Регистрация</button>
              </div>
            </div>
            <!-- Кнопка для переключения на окно входа -->
            <div class="field-row field-row-account">
              <div class="field-item">
                Уже есть аккаунт?
                <button class="button button-white" @click="switchToLogin">Войти</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showRegisterPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeRegisterPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeRegisterPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Спасибо за регистрацию.
            <br>Пароль для входа отправлен в&nbsp;смс
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для вопроса -->
    <div v-if="showQuestionPopup" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopup"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopup" class="popup__close"></button>
        <div class="popup__title">Обратная связь</div>
        <div class="popup__content">
          <form @submit.prevent="submitQuestion" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-name">Имя</label>
                <input
                    type="text"
                    v-model="questionForm.name"
                    id="feedback-name"
                    name="name"
                    @input="removeSpacesName"
                    :disabled="user && !!user.first_name" />
                <span v-if="feedbackErrors.name" class="error-message">{{ feedbackErrors.name }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-email">Email</label>
                <input
                    type="text"
                    v-model="questionForm.email"
                    id="feedback-email"
                    name="email"
                    :disabled="user && !!user.email" />
                <span v-if="feedbackErrors.email" class="error-message">{{ feedbackErrors.email }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="feedback-message">Сообщение</label>
                <textarea v-model="questionForm.text" id="feedback-message" name="text"></textarea>
                <span v-if="feedbackErrors.text" class="error-message">{{ feedbackErrors.text }}</span>
              </div>
            </div>
            <div class="field-row" data-cols="1">
              <div class="field-item field-item-checkbox">
                <label for="feedback-personality">
                  <input type="checkbox" id="feedback-personality" v-model="questionForm.agree_personal_data">
                  <span>Согласен(на) <a v-if="mainSettings.policy" v-bind:href="mainSettings.policy" target="_blank" class="link">с обработкой персональных данных</a></span>
                </label>
                <span v-if="feedbackErrors.agree_personal_data" class="error-message">{{ feedbackErrors.agree_personal_data }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showQuestionPopupSuccess" class="popup">
      <div class="popup__overlay" @click="closeQuestionPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeQuestionPopupSuccess" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Ваше обращение принято
            <br>Мы свяжемся с вами в ближайшее время
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для восстановления пароля -->
    <div v-if="showResetPasswordPopup" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopup"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopup" class="popup__close"></button>
        <div class="popup__title">Восстановление пароля</div>
        <div class="popup__content">
          <form @submit.prevent="submitResetPassword" autocomplete="off">
            <div class="field-row" data-cols="1">
              <div class="field-item">
                <label for="reg-phone">Номер телефона</label>
                <input type="text" maxlength="18" id="reg-phone" v-model="resetPasswordForm.phone_number" name="phone_number"
                       :class="{ 'is-error': resetPasswordErrors.phone_number }" v-mask>
                <span v-if="resetPasswordErrors.phone_number" class="error-message">{{ resetPasswordErrors.phone_number }}</span>
              </div>
            </div>
            <div class="field-row field-row-action" data-cols="1">
              <div class="field-item">
                <div class="response-error" v-if="responseError">{{ responseError }}</div>
                <button type="submit" class="button button-yellow">Отправить</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div v-if="showResetPasswordSuccess" class="popup">
      <div class="popup__overlay" @click="closeResetPasswordPopupSuccess"></div>
      <div class="popup__window">
        <button @click="closeResetPasswordPopupSuccess" class="popup__close"></button>
        <div class="popup__title">
          Восстановление пароля
        </div>
        <div class="popup__content">
          <div class="success-message">
            Новый пароль был отправлен на номер телефона {{ formattedPhoneNumber }}
          </div>
        </div>
      </div>
    </div>

    <!-- Popup для временного закрытия ЛК -->
    <div v-if="AccountPopup" class="popup">
      <div class="popup__overlay" @click="closeAccountPopup"></div>
      <div class="popup__window">
        <button @click="closeAccountPopup" class="popup__close"></button>
        <div class="popup__title">
          Личный кабинет
        </div>
        <div class="popup__content">
          <div class="success-message">
            Личный кабинет временно недоступен.
            <br>Мы уже знаем о проблеме и работаем над ее устранением
          </div>
        </div>
      </div>
    </div>

    <div v-if="showPromoEnd" class="popup">
      <div class="popup__overlay" @click="closeShowPromoEnd"></div>
      <div class="popup__window">
        <button @click="closeShowPromoEnd" class="popup__close"></button>
        <div class="popup__content">
          <div class="success-message">
            Акция завершена
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import { ref, onMounted, nextTick } from 'vue';
import { fetchGoods, fetchFavoriteRecipes, fetchFAQ, getToken, getUserInfo, removeToken, authorize, register, getMainSettings, sendFeedback, setAuthHeader, sendResetPassword, getWinningsByWeek, getPrizes, getAllRecipes, getTags } from '@/api';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default {
  name: 'RecipesPage',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const mainWinner = ref({
      name: '',
      phone: '',
    });

    const selectedWeek = ref(1);
    const winners = ref([]);
    const nextDrawDate = ref('10 июля');
    const availableWeeks = ref({});
    // const prizes = ref([]);
    const weeks = ref([
      { index: 1, date: '10 июля 2024 г.' },
      { index: 2, date: '17 июля 2024 г.' },
      { index: 3, date: '24 июля 2024 г.' },
      { index: 4, date: '31 июля 2024 г.' },
      { index: 5, date: '7 августа 2024 г.' },
      { index: 6, date: '14 августа 2024 г.' },
      { index: 7, date: 'Главный' },
    ]);

    const buttonRefs = ref({});

    const fetchWinnings = async (week) => {
      try {
        const data = await getWinningsByWeek(week);
        if (data.count > 0) {
          winners.value = data.results;
        } else {
          winners.value = [];
        }
      } catch (error) {
        console.error('Error fetching winnings:', error);
        winners.value = [];
      }
    };

    const checkWeeksAvailability = async () => {
      const weeksToCheck = [1, 2, 3, 4, 5, 6, 7];

      for (const week of weeksToCheck) {
        try {
          const data = await getWinningsByWeek(week);
          availableWeeks.value[week] = data.count > 0;
          const specialPrize = data.results.find(item => item.prize.type === 2);
          if (specialPrize) {
            mainWinner.value.name = specialPrize.winner.name;
            mainWinner.value.phone = specialPrize.winner.phone_number;
          }
        } catch (error) {
          availableWeeks.value[week] = false;
        }
      }

      selectWeek(findLastActiveWeek());
    };

    const selectWeek = (week) => {
      selectedWeek.value = week;
      fetchWinnings(week);
      updateNextDrawDate(week);
    };

    const findLastActiveWeek = () => {
      for (let week = 7; week >= 1; week--) {
        if (availableWeeks.value[week]) {
          return week;
        }
      }
      return 1; // Default to week 1 if no active weeks found
    };

    const updateNextDrawDate = (week) => {
      const dates = [
        '10 июля',
        '17 июля',
        '24 июля',
        '31 июля',
        '7 августа',
        '14 августа',
        'Главный',
      ];
      nextDrawDate.value = dates[week - 1];
    };

    const toggleWinnersAccordion = (week) => {
      if (selectedWeek.value === week.index) {
        selectedWeek.value = null;
      } else {
        selectWeek(week.index);

        // Прокрутка к началу кнопки
        if (window.innerWidth <= 767) { // Условие для мобильных устройств
          nextTick(() => {
            buttonRefs.value[week.index].scrollIntoView({ behavior: 'smooth', block: 'start' });
          });
        }
      }
    };

    onMounted(async () => {
      await checkWeeksAvailability();
    });

    return {
      modules: [ Navigation, Pagination ],
      // Настройки для первого Swiper
      breakpoints1: {
        320: {
          slidesPerView: 1,
        },
      },
      pagination1: { clickable: true },
      navigation1: false,

      // Настройки для второго Swiper
      breakpoints2: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 2,
        },
      },
      pagination2: false,
      navigation2: true,

      // Настройки для третьего Swiper
      breakpoints3: {
        320: {
          slidesPerView: 1,
        }
      },
      pagination3: false,
      navigation3: true,
      selectedWeek,
      winners,
      nextDrawDate,
      availableWeeks,
      mainWinner,
      selectWeek,
      weeks,
      toggleWinnersAccordion,
      buttonRefs,
    };
  },
  data() {
    return {
      isLoading: true,
      tagsList: [],
      activeTag: null, // Храним выбранный тег
      filteredRecipes: [], // Отфильтрованные рецепты
      tabs: ['Гарантированные', 'Специальные'],
      selectedTab: 'Гарантированные',
      prizes: [], // Все призы
      guaranteedPrizes: [], // Призы с типом 0 (гарантированные)
      specialPrizes: [], // Призы с типом 3 (специальные)
      goods: [],                    // Массив товаров
      limit: 12,                     // Количество товаров на странице
      offset: 0,                    // Смещение для пагинации
      hasMoreGoods: true,           // Флаг для отображения кнопки "Больше товаров"
      allRecipes: [],
      recipes: [],
      rootUrl: window.location.origin,
      userId: '',
      isMobileNavOpened: false,
      faq: [],
      user: null,
      showLoginPopup: false,
      showRegisterPopup: false,
      showRegisterPopupSuccess: false,
      showQuestionPopup: false,
      showQuestionPopupSuccess: false,
      showResetPasswordPopup: false,
      showResetPasswordSuccess: false,
      showPromoEnd: false,
      AccountPopup: false,
      questionForm: {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      },
      phone_number: '',
      password: '',
      passwordVisible: false,
      registerForm: {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      },
      loginForm: {
        phone_number: '',
        password: '',
      },
      loginFormErrors: {},
      resetPasswordForm: {
        phone_number: '',
      },
      feedbackErrors: {},
      resetPasswordErrors: {},
      errors: {},
      responseError: '',
      days: 0,
      hours: 0,
      minutes: 0,
      timerMessage: '',
      mainSettings: {},
      sections: [
        { name: 'Покупай:', text: 'от 1-й упаковки продукции:', component: 'PurchaseSection', scrolled: false },
        { name: 'Регистрируй', text: 'чеки на промо-сайте или в чат-боте', component: 'RegisterSection', scrolled: false},
        { name: 'Выигрывай', text: 'круиз до Углича на двоих или мастер-класс от шеф-повара Григория Мосина и другие ценные призы', component: 'PrizeSection', scrolled: false },
        { name: 'Выкладывай', text: 'кулинарные шедевры в на странице Вконтакте для участия в специальном розыгрыше призов', component: 'ShareSection', scrolled: false }
      ],
      activeSection: 0,
      scrolledSection: -1

    };
  },
  async created() {
    try {
      // Получаем теги
      this.tagsList = await getTags();

      // Загружаем все рецепты
      const recipesData = await getAllRecipes();
      this.allRecipes = recipesData.results;
      this.filteredRecipes = this.allRecipes;
    } catch (error) {
      console.error('Error loading data:', error);
    }
    try {
      // Запросы выполняются параллельно
      const [recipesData, mainSettings] = await Promise.all([
        fetchFavoriteRecipes(),
        getMainSettings(),
      ]);

      // Присваивание данных
      this.recipes = recipesData.results;
      this.mainSettings = mainSettings;

      // Вызов других функций
      this.loadFAQ();
      this.checkAuthentication();
      this.fetchTimerData();

    } catch (error) {
      console.error('Ошибка при загрузке данных:', error);
    } finally {
      this.isLoading = false;
    }
    await this.fetchAllRecipes();
    await this.fetchPrizes();
    await this.loadGoods();
  },
  mounted() {
    setAuthHeader();
    const token = getToken();
    if (token) {
      getUserInfo().then(userInfo => {
        if (userInfo) {
          this.userId = userInfo.id;
          this.user = {
            first_name: userInfo.first_name,
            last_name_initial: userInfo.last_name.charAt(0),
          };
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        }
      }).catch(error => {
        console.error('Failed to fetch user info on mount', error);
      });
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    filteredPrizes() {
      return this.prizes.filter(prize => prize.type === 1);
    },
    formattedPhoneNumber() {
      // Убираем знак '+' и все нецифровые символы
      const cleaned = this.phoneNumber.replace(/\D/g, '');

      // Проверяем, что номер начинается с '7' и содержит 11 цифр
      if (cleaned.length === 11 && cleaned.startsWith('7')) {
        return `+7 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
      }

      return ' ';
    }
  },
  methods: {
    filterByTag(tagId) {
      if (this.activeTag === tagId) {
        // Если активный тег тот же, что был нажат — сбрасываем фильтр
        this.resetFilter();
      } else {
        // Иначе — фильтруем по новому тегу
        this.activeTag = tagId;
        this.filteredRecipes = this.allRecipes.filter(recipe =>
            recipe.tags.some(tag => tag.id === tagId)
        );
      }
    },
    resetFilter() {
      this.activeTag = null;
      this.filteredRecipes = this.allRecipes;
    },
    async fetchAllRecipes() {
      try {
        const data = await getAllRecipes(); // Запрашиваем рецепты
        this.allRecipes = data.results; // Сохраняем рецепты
      } catch (error) {
        console.error('Ошибка при загрузке рецептов:', error);
      }
    },
    async fetchPrizes() {
      try {
        const prizes = await getPrizes(); // Получаем призы из API
        this.prizes = prizes;

        // Фильтруем призы по типам
        this.guaranteedPrizes = this.prizes.filter(prize => prize.type === 0);
        this.specialPrizes = this.prizes.filter(prize => prize.type === 3);
      } catch (error) {
        console.error('Ошибка при получении призов:', error);
      }
    },
    selectTab(tab) {
      this.selectedTab = tab; // Изменяем активный таб
    },
    async loadGoods() {
      try {
        const data = await fetchGoods(this.limit, this.offset);
        this.goods = [...this.goods, ...data.results];
        this.offset += this.limit;
        if (data.results.next == null) {
          this.hasMoreGoods = false;
        }
      } catch (error) {
        console.error('Error loading goods:', error);
      }
    },
    async loadMoreGoods() {
      await this.loadGoods();
    },
    goToRecipe(id) {
      // Переход на страницу рецепта
      this.$router.push({ name: 'Recipe', params: { id } });
      window.scrollTo(0, 0); // Прокрутка страницы до верха
    },
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
    formatText(text) {
      // Замена переносов строк на <br>
      let formattedText = text.replace(/\r?\n/g, '<br>');

      // Регулярное выражение для поиска URL
      const urlPattern = /(https?:\/\/[^\s]+)/g;

      // Замена URL на HTML-ссылки
      formattedText = formattedText.replace(urlPattern, '<a href="$1" target="_blank">$1</a>');

      return formattedText;
    },
    clearError(field) {
      this.errors = { ...this.errors, [field]: null };
    },
    goToGamePage() {
      this.$router.push('/game');
      setTimeout(function(){
        window.scrollTo({
          top: '0',
          behavior: 'smooth'
        });
      }, 200)
    },
    redirectToPrivatePage() {
      this.$router.push('/private');
      this.isMobileNavOpened = false;
    },
    redirectToHomePage() {
      this.$router.push('/');
    },
    removeSpacesFirstName() {
      const input = event.target.value.trimStart();
      this.registerForm.first_name = input.length > 0 ? input : '';
    },
    removeSpacesLastName() {
      const input = event.target.value.trimStart();
      this.registerForm.last_name = input.length > 0 ? input : '';
    },
    removeSpacesName() {
      const input = event.target.value.trimStart();
      this.questionForm.name = input.length > 0 ? input : '';
    },
    triggerQrScan() {
      this.showPromoEnd = true;
      // const qrElement = document.getElementById('apm-scan-qr');
      // if (qrElement) {
      //   qrElement.click();
      // }
    },
    closeShowPromoEnd() {
      this.showPromoEnd = false;
    },
    toggleMobileNav() {
      this.isMobileNavOpened = !this.isMobileNavOpened;
    },
    openAccountPopup() {
      this.AccountPopup = true;
    },
    closeAccountPopup() {
      this.AccountPopup = false;
    },
    openQuestionPopup() {
      this.showQuestionPopup = true;
    },
    closeQuestionPopup() {
      this.showQuestionPopup = false;
      this.resetForm();
    },
    openQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = true;
      this.$sendMetrik('reachGoal', 'Feedback_SentForm_Success');
    },
    closeQuestionPopupSuccess() {
      this.showQuestionPopupSuccess = false;
      this.resetForm();
    },
    openResetPasswordPopup() {
      this.showResetPasswordPopup = true;
      this.closeLoginPopup();
    },
    closeResetPasswordPopup() {
      this.showResetPasswordPopup = false;
      this.resetForm();
    },
    openResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = true;
    },
    closeResetPasswordPopupSuccess() {
      this.showResetPasswordSuccess = false;
      this.resetForm();
    },
    async submitQuestion() {
      try {
        // Валидация формы перед отправкой
        this.feedbackErrors = {};
        if (!this.questionForm.name) {
          this.feedbackErrors.name = 'Введите имя';
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.questionForm.email)) {
          this.feedbackErrors.email = 'Введите корректный email';
        }
        if (!this.questionForm.text) {
          this.feedbackErrors.text = 'Введите сообщение';
        }
        if (!this.questionForm.agree_personal_data) {
          this.feedbackErrors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }

        if (Object.keys(this.feedbackErrors).length > 0) {
          return; // Если есть ошибки валидации, не отправляем запрос
        }

        // Отправка данных формы через API
        await sendFeedback({
          email: this.questionForm.email,
          name: this.questionForm.name,
          text: this.questionForm.text
        });

        // Закрыть попап после успешной отправки
        this.openQuestionPopupSuccess();
        this.closeQuestionPopup();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async submitResetPassword() {
      try {
        const { phone_number } = this.resetPasswordForm;
        this.resetPasswordErrors = {};
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g, '')}`;

        // Валидация длины телефонного номера
        if (formattedPhoneNumber.length < 12) {
          this.resetPasswordErrors.phone_number = 'Введите корректный телефон';
          return; // Выходим, если есть ошибка валидации
        }

        // Отправка данных формы через API
        await sendResetPassword({ phone_number: formattedPhoneNumber });

        // Успешная отправка, здесь можно закрыть попап
        this.phoneNumber = formattedPhoneNumber;
        this.closeResetPasswordPopup();
        this.openResetPasswordPopupSuccess();
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    resetForm() {
      this.questionForm = {
        name: this.user && this.user.first_name ? this.user.first_name : '',
        email: this.user && this.user.email ? this.user.email : '',
        text: '',
        agree_personal_data: false
      };

      this.phone_number = '';
      this.password = '';

      this.registerForm = {
        first_name: '',
        last_name: '',
        phone_number: '',
        email: '',
        agree_personal_data: false,
        agree_rules: false
      };

      this.resetPasswordForm = {
        phone_number: '',
      };

      this.feedbackErrors = {};
    },
    toggleAccordion(event) {
      event.target.classList.toggle('is-opened');
      const textBlock = event.target.nextElementSibling;
      textBlock.classList.toggle('is-opened');
    },
    handleScroll() {
      const sections = document.querySelectorAll('.register-section');

      let currentSectionIndex = -1;

      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top - 400 <= 0 && rect.bottom > 0) {
          currentSectionIndex = index;
        }
      });

      if (currentSectionIndex !== -1) {
        this.activeSection = currentSectionIndex;
        // Добавляем класс 'is-scrolled' ко всем предыдущим секциям
        for (let i = 0; i < currentSectionIndex; i++) {
          this.sections[i].scrolled = true;
        }
        // Убираем класс 'is-scrolled' со всех следующих секций
        for (let i = currentSectionIndex; i < this.sections.length; i++) {
          this.sections[i].scrolled = false;
        }
      } else {
        this.activeSection = 0;
        this.sections.forEach(section => section.scrolled = false);
      }
    },
    scrollToSection(index) {
      const section = this.$refs['section-' + index][0];
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth'
      });
      this.activeSectionIndex = index;
    },
    isActive(index) {
      return this.activeSectionIndex === index;
    },
    isScrolled(index) {
      return index < this.activeSectionIndex;
    },
    async checkAuthentication() {
      const token = getToken();
      if (token) {
        try {
          const userInfo = await getUserInfo();
          this.user = userInfo;
          this.questionForm.name = userInfo.first_name;
          this.questionForm.email = userInfo.email;
        } catch (error) {
          if (error.response && error.response.data) {
            this.responseError = error.response.data.detail;
          }
        }
      }
    },
    async fetchTimerData() {
      try {
        const { start_date, end_date } = await getMainSettings();

        const now = new Date();
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);
        endDate.setDate(endDate.getDate() + 1); // Увеличиваем дату окончания на 1 день
        endDate.setHours(0, 0, 0, 0); // Устанавливаем время окончания на 00:00 следующего дня

        if (now < startDate) {
          // Если акция еще не началась
          this.calculateTimeDifference(now, startDate);
          this.timerMessage = 'до начала акции';
        } else if (now >= startDate && now < endDate) {
          // Если акция идет в данный момент
          this.calculateTimeDifference(now, endDate);
          this.timerMessage = 'До окончания приема чеков';
        } else {
          // Если акция уже завершилась
          this.days = 0;
          this.hours = 0;
          this.minutes = 0;
          this.timerMessage = 'Акция завершилась';
        }
      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    calculateTimeDifference(start, end) {
      const diff = end - start;

      const totalSeconds = Math.floor(diff / 1000);
      this.days = Math.floor(totalSeconds / (3600 * 24));
      this.hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
      this.minutes = Math.floor((totalSeconds % 3600) / 60);
    },
    openLoginPopup() {
      this.isMobileNavOpened = false;
      this.showLoginPopup = true;
      this.responseError = '';
    },
    closeLoginPopup() {
      this.showLoginPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopup() {
      this.showRegisterPopup = true;
      this.closeLoginPopup();
      this.responseError = '';
    },
    closeRegisterPopup() {
      this.showRegisterPopup = false;
      this.responseError = '';
      this.resetForm();
    },
    openRegisterPopupSuccess() {
      this.$sendMetrik('reachGoal', 'SignUp_SentForm_Success');
      this.showRegisterPopupSuccess = true;
      this.responseError = '';
    },
    closeRegisterPopupSuccess() {
      this.showRegisterPopupSuccess = false;
      this.responseError = '';
      this.resetForm();
    },
    async loadFAQ() {
      try {
        const data = await fetchFAQ();
        this.faq = data.results.map(item => ({
          question: item.question,
          answer: item.answer
        }));
      } catch (error) {
        console.error('Error fetching winners:', error);
      }
    },
    async login() {
      try {
        const formattedPhoneNumber = `+${this.phone_number.replace(/\D/g,'')}`;
        this.loginFormErrors = {};
        if (formattedPhoneNumber.length < 12) {
          this.loginFormErrors.phone_number = 'Введите корректный телефон';
        }
        if (this.password.length < 2) {
          this.loginFormErrors.password = 'Введите пароль';
        }
        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.loginFormErrors).length > 0) {
          return;
        }

        await authorize(formattedPhoneNumber, this.password);
        setAuthHeader();
        setTimeout(async () => {
          try {
            const userInfo = await getUserInfo();
            if (userInfo) {
              this.userId = userInfo.id;
              this.user = {
                first_name: userInfo.first_name,
                last_name_initial: userInfo.last_name.charAt(0),
              };
              this.questionForm.name = userInfo.first_name;
              this.questionForm.email = userInfo.email;
              this.closeLoginPopup();
              this.$router.push('/private');
            }
          } catch (error) {
            console.error('Error fetching user info', error);
          }
        }, 900); // 500 мс задержка

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    async register() {
      try {
        const { first_name, last_name, phone_number, email, agree_personal_data, agree_rules } = this.registerForm;

        // Форматирование номера телефона с добавлением префикса +7
        const formattedPhoneNumber = `+${phone_number.replace(/\D/g,'')}`;

        // Проверка полей
        this.errors = {};
        if (first_name.length < 2) {
          this.errors.first_name = 'Введите корректное значение';
        }
        if (last_name.length < 2) {
          this.errors.last_name = 'Введите корректное значение';
        }
        // Пример простой проверки email на наличие символов @ и .
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
          this.errors.email = 'Введите корректный email';
        }
        // Пример простой проверки телефона по длине
        if (formattedPhoneNumber.length < 12) {
          this.errors.phone_number = 'Введите корректный телефон';
        }
        if (!agree_personal_data) {
          this.errors.agree_personal_data = 'Необходимо согласиться с обработкой персональных данных';
        }
        if (!agree_rules) {
          this.errors.agree_rules = 'Необходимо согласиться с правилами акции';
        }

        // Если есть ошибки - не отправляем запрос
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Отправка запроса на регистрацию с форматированным номером телефона
        await register(first_name, last_name, formattedPhoneNumber, email);

        // Закрываем окно регистрации
        this.closeRegisterPopup();
        this.openRegisterPopupSuccess();

      } catch (error) {
        if (error.response && error.response.data) {
          this.responseError = error.response.data.detail;
        }
      }
    },
    switchToLogin() {
      this.showLoginPopup = true;
      this.showRegisterPopup = false;
    },
    logout() {
      const widget = document.getElementById('apm-widget');
      if (widget) {
        widget.innerHTML = '';
      }
      this.toggleMobileNav();
      removeToken();
      this.questionForm = {
        name: '',
        email: '',
        text: '',
        agree_personal_data: false
      };
      this.user = null;
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>

</style>
