import axios from 'axios';

// Константа для базового URL API
const API_URL = process.env.VUE_APP_API_URL;


// Функция для получения списка тегов
export const getTags = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/recipes/tags`);
        return response.data.results; // Возвращаем только массив результатов
    } catch (error) {
        console.error('Error fetching tags:', error);
        throw error;
    }
};

export const changePassword = async (new_password) => {
    try {
        await axios.patch(`${API_URL}/api/users/change-password`, {
            new_password
        });
    } catch (error) {
        console.error('Error during profile update', error);
        throw error;
    }
};

// Рецепты
export const fetchFavoriteRecipes = async () => {
    const endpoint = `${API_URL}/api/recipes/?is_favorite=true&limit=2`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching favorite recipes:", error);
        throw error;
    }
};

// Рецепты без favorite
export const getAllRecipes = async () => {
    const endpoint = `${API_URL}/api/recipes/?limit=100`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching non-favorite recipes:", error);
        throw error;
    }
};


// Рецепты без favorite
export const getHomeRecipes = async () => {
    const endpoint = `${API_URL}/api/recipes/?limit=4`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching non-favorite recipes:", error);
        throw error;
    }
};

// Детальная страница рецепта
export const fetchRecipeById = async (id) => {
    const endpoint = `${API_URL}/api/recipes/${id}`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error('Ошибка получения рецепта:', error);
        throw error;
    }
};

// Товары
export const fetchGoods = async (limit, offset) => {
    try {
        const response = await axios.get(`${API_URL}/api/goods/`, {
            params: {
                limit: limit,
                offset: offset
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching goods:', error);
        throw error;
    }
};


// Функция для установки токена в заголовок Authorization
export const setAuthHeader = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenURL = urlParams.get('token');
    let token = '';
    if (tokenURL) {
        token = tokenURL;
    } else {
        token = getToken();
    }
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axios.defaults.headers.common['Authorization'];
    }
};

// Добавляем интерцептор для обработки ответов
axios.interceptors.response.use(
  response => response,
  error => {
      if (error.response && error.response.status === 401) {
          removeToken();
          delete axios.defaults.headers.common['Authorization'];
      }
      return Promise.reject(error);
  }
);

export const getWinningsByWeek = async (week) => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/winnings?week=${week}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winnings by week', error);
        throw error;
    }
};

// Функция для выполнения запроса на получение данных о призах
export const getWinningPrizes = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/winning-prizes`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winning prizes', error);
        throw error;
    }
};

// Функция для получения списка призов
export const getPrizes = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/quiz/prizes`);
        return response.data.results; // Возвращаем только массив результатов
    } catch (error) {
        console.error('Error fetching prizes:', error);
        throw error;
    }
};

/**
 * Отправка PATCH-запроса для подтверждения гарантированного приза
 * @param {Number} receiptId - ID чека
 * @param {Object} data - Объект с данными для обновления (в данном случае гарантированный приз)
 * @returns {Promise<Object>} - Результат выполнения запроса
 */
export async function patchReceiptPrize(receiptId, id) {
    try {
        const response = await axios.patch(`${API_URL}/api/quiz/receipts/${receiptId}/guaranteed-prize`, {
            guaranteed_prize_id: id
        });
        return response.data;
    } catch (error) {
        // Обрабатываем ошибку
        console.error(`Error updating guaranteed prize for receipt ${receiptId}:`, error);
        throw error; // Выбрасываем ошибку для обработки в компоненте
    }
}

// Функция для выполнения запроса на получение чеков пользователя
export const getAllUserChecks = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/receipts?limit=10000`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winning prizes', error);
        throw error;
    }
};

// Функция для выполнения запроса на получение чеков пользователя
export const getUserChecks = async (offset, limit) => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/quiz/receipts?offset=${offset}&limit=${limit}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching winning prizes', error);
        throw error;
    }
};

// Функции для работы с токенами, пользователем, настройками и т.д. остаются такими же, как у вас в текущем файле запросов
export const getToken = () => localStorage.getItem('token');

export const setToken = (token) => localStorage.setItem('token', token);

export const removeToken = () => localStorage.removeItem('token');

export const getUserInfo = async () => {
    try {
        setAuthHeader();
        const response = await axios.get(`${API_URL}/api/users/me`);
        return response.data;
    } catch (error) {
        console.error('Error fetching user info', error);
        throw error;
    }
};

export const authorize = async (phone_number, password, token) => {
    try {
        const response = await axios.post(`${API_URL}/api/users/authorization`, {
            phone_number,
            password,
            token,
        });
        setToken(response.data.access);
        setAuthHeader();
        return response.data;
    } catch (error) {
        console.error('Ошибка во время авторизации', error);
        throw error;
    }
};

export const register = async (first_name, last_name, phone_number, email) => {
    try {
        await axios.post(`${API_URL}/api/users/registration`, {
            first_name,
            last_name,
            phone_number,
            email,
        });
    } catch (error) {
        console.error('Error during registration', error);
        throw error;
    }
};

export const changeProfile = async (first_name, last_name, email) => {
    try {
        await axios.put(`${API_URL}/api/users/me`, {
            first_name,
            last_name,
            email,
        });
    } catch (error) {
        console.error('Error during profile update', error);
        throw error;
    }
};

export const getMainSettings = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/main/settings`);
        return response.data;
    } catch (error) {
        console.error('Error fetching main settings', error);
        throw error;
    }
};

export const fetchFAQ = async () => {
    const endpoint = `${API_URL}/api/feedback/faq?limit=100`;
    try {
        const response = await axios.get(endpoint);
        return response.data;
    } catch (error) {
        console.error("Error fetching FAQ:", error);
        throw error;
    }
};

export const sendFeedback = async (message) => {
    const endpoint = `${API_URL}/api/feedback/send`;
    const formData = new FormData();

    formData.append('email', message.email);
    formData.append('name', message.name);
    formData.append('text', message.text);

    try {
        const response = await axios.post(endpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};

export const sendResetPassword = async (phone) => {
    const endpoint = `${API_URL}/api/users/recovery-password`;
    const formData = new FormData();

    formData.append('phone_number', phone.phone_number);

    try {
        const response = await axios.post(endpoint, formData);
        return response.data;
    } catch (error) {
        console.error('Error sending feedback:', error.response);
        throw error;
    }
};

export default {
    getToken,
    setToken,
    removeToken,
    getUserInfo,
    authorize,
    register,
    changeProfile,
    getMainSettings,
    getWinningPrizes, // Добавляем функцию для получения данных о призах
    fetchFAQ,
    sendFeedback,
    sendResetPassword,
};