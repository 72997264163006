<template>
  <div :key="$route.params.id" v-if="!isLoading && recipe">
    <section id="recipes-detail">
      <div class="recipe-detail">
        <button @click="goToRecipes" class="button button-back">Назад</button>
        <h1>{{ recipe.title }}</h1>
        <div class="recipes-detail">
          <div class="recipes-detail__left">
            <div class="recipes-detail__tags">
              <span v-for="tag in recipe.tags" :key="tag.id">{{ tag.title }}</span>
            </div>
            <div class="recipes-detail__description">
              <p>{{ recipe.description }}</p>
            </div>
            <div class="recipes-detail__media">
              <img :src="recipe.image_url" loading="lazy" />
              <div>
                <a :href="vkShareUrl" target="_blank" rel="noopener noreferrer" class="share-vk-btn">
                  Поделиться рецептом
                </a>

                <button v-if="recipe.video_url" @click="openVideoPopup(recipe.id)">
                  Смотреть видео-рецепт
                </button>
              </div>
            </div>
          </div>
          <div class="recipes-detail__right">
            <div class="recipes-detail-ingredients" v-for="(ingredientList, index) in recipe.ingredient_lists" :key="index">
              <div class="recipes-detail-ingredients__title">
                {{ ingredientList.title }}
              </div>
              <div class="recipes-detail-ingredients__list">
                <div class="recipes-detail-ingredients__list-item" v-for="(ingredient, idx) in ingredientList.ingredients" :key="idx">
                  <div v-html="ingredient.title"></div>
                  <div>{{ ingredient.value }}</div>
                </div>
              </div>
            </div>
            <div class="recipes-detail-steps">
              <div class="recipes-detail-steps__item" v-for="step in recipe.steps" :key="step.number">
                <div class="recipes-detail-steps__item-num">
                  {{ step.number }}
                </div>
                <div class="recipes-detail-steps__item-text">
                  {{ step.description }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="share-concurs">
      <div class="section-title">
        Присоединяйся к конкурсу
      </div>
      <div class="share-concurs">
        <div class="share-concurs__info">
          <div class="share-concurs__info-title">
            Шедевры с углече поле
          </div>
          <div class="share-concurs__info-description">
            Приготовь блюдо с продуктами Углече Поле, сделай фото и размести у себя на странице в ВК с хештегом
            <br>#Углечеполешедевры
          </div>
          <div class="share-concurs__info-btn">
            <button class="button button-white" @click="goToRecipes">Все рецепты</button>
          </div>
        </div>
        <div class="share-concurs__media">
          <img src="@/assets/img/main/share-img.jpg" loading="lazy"
               srcset="@/assets/img/main/share-img@2x.jpg 2x" alt="">
        </div>
      </div>
    </section>

    <section id="recipes">
      <div class="section-title">Смотрите так же</div>
      <div class="recipes-list">
        <div
            class="recipes-list-item"
            v-for="(recipe, index) in allRecipes"
            :key="index"
            @click="goToRecipe(recipe.id)"
        >
          <div class="recipes-list-item__img">
            <img :src="recipe.image_url" :alt="recipe.title" loading="lazy" />
          </div>
          <div class="recipes-list-item__info">
            <div class="recipes-list-item__info-title">
              {{ recipe.title }}
            </div>
            <div class="recipes-list-item__info-description">
              {{ recipe.preview }}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <div v-else class="preloader">
    <div class="preloader">
      <div class="spinner"></div>
    </div>
  </div>

  <div v-if="showVideoPopup" class="popup popup--video">
    <div class="popup__overlay"></div>
    <div class="popup__window">
      <button @click="closeVideoPopup" class="popup__close"></button>
      <div class="popup__title">
        {{ recipe.title }}
      </div>
      <div class="popup__content">
        <video v-if="recipe.video_url" ref="videoPlayer"  class="video-recipe" preload="none" controls :poster="recipe.image_url">
          <source :src="recipe.video_url" type="video/mp4">
        </video>
      </div>
    </div>
  </div>

</template>

<script>
import {fetchRecipeById, getHomeRecipes} from '@/api';
import metrikaMixin from '@/mixins/metrikaMixin';

export default {
  mixins: [metrikaMixin],
  data() {
    return {
      showVideoPopup: false,
      isLoading: true,
      recipe: null,
      allRecipes: [],
    };
  },
  computed: {
    vkShareUrl() {
      const url = encodeURIComponent(window.location.href);
      const title = encodeURIComponent(`#Углечеполешедевры ${this.recipe.title || ''}`);
      const description = encodeURIComponent(this.recipe.description || '');
      const imageUrl = encodeURIComponent(this.recipe.image_url || '');
      return `https://vk.com/share.php?url=${url}&title=${title}&description=${description}&image=${imageUrl}&noparse=true`;
    }
  },
  watch: {
    '$route.params.id': 'fetchRecipeData' // Слежение за изменением id в маршруте
  },
  methods: {
    openVideoPopup(id) {
      this.$sendMetrik('reachGoal', 'video-'+id);
      this.showVideoPopup = true;
    },
    closeVideoPopup() {
      this.showVideoPopup = false;
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.pause();
        videoPlayer.currentTime = 0; // Сброс времени воспроизведения
      }
    },
    async fetchAllRecipes() {
      try {
        const data = await getHomeRecipes(); // Запрашиваем рецепты
        this.allRecipes = data.results; // Сохраняем рецепты
      } catch (error) {
        console.error('Ошибка при загрузке рецептов:', error);
      }
    },
    async fetchRecipeData() {
      this.isLoading = true;
      try {
        const data = await fetchRecipeById(this.$route.params.id); // Получаем данные рецепта по ID
        this.recipe = data;
      } catch (error) {
        console.error('Ошибка загрузки рецепта:', error);
      } finally {
        this.isLoading = false;
      }
    },
    goToRecipes() {
      this.$router.push({ name: 'RecipesPageList' }); // Переход на страницу всех рецептов
      window.scrollTo(0, 0); // Прокрутка страницы до верха
    },
    goToRecipe(id) {
      // Переход на страницу рецепта
      this.$router.push({ name: 'Recipe', params: { id } });
      window.scrollTo(0, 0); // Прокрутка страницы до верха
    },
  },
  async created() {
    await this.fetchAllRecipes();
    await this.fetchRecipeData(); // Получаем данные рецепта при создании компонента
  },
};
</script>

<style scoped>
</style>