import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import TGHomePage from '@/views/TGHomePage.vue';
import TGPrivatePage from '@/views/TGPrivatePage.vue';
import TGUploadCheck from '@/views/TGUploadCheck.vue';
import PrivatePage from '@/views/PrivatePage.vue';
import RecipePage from '@/views/RecipePage.vue';
import RecipesPageList from '@/views/RecipesPageList.vue';
import { getToken } from '@/api'; // Замените на вашу реализацию

const routes = [
  { path: '/', component: HomePage },
  { path: '/tg-home-page', name: 'TGHomePage', component: TGHomePage },
  {
    path: '/recipes/:id',
    name: 'Recipe',
    component: RecipePage,
    props: true,
  },
  {
    path: '/recipes',
    name: 'RecipesPageList',
    component: RecipesPageList,
  },
  { path: '/private', component: PrivatePage, meta: { requiresAuth: true } },
  { path: '/tg-private-page', name: 'TGPrivatePage', component: TGPrivatePage },
  { path: '/tg-upload-check', name: 'TGUploadCheck', component: TGUploadCheck },
  // Другие маршруты
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const isAuthenticated = !!getToken(); // Замените на вашу реализацию

  if (requiresAuth && !isAuthenticated) {
    next('/');
  } else {
    next();
  }
});

export default router;
